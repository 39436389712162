<template>
  <div>
    <topBarY />
    <div class="bg">
      <img src="../../assets/common/pic/bgs.jpg" alt="">
    </div>
    <div class="switchBar">
      <div class="tab contact">杭州BRT快速公交无线视频传输项目</div>
    </div>
    <div class="line"></div>
    <div class="switchContiner">
      <div class="continer contactContiner">
        <div class="txt">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BRT(Bus Rapid Transit)快速公交目前杭州已经开通3 条快速线路，做为全国快速公交数量及运行领先的杭州，
          杭州公交总公司为了更好地便于每个站台的安全管理、实时的了解每个站点运行状况及乘客的流量，
          在每路快速公交停靠站点的相对应的两个站台分别安装了四路摄像机，用无线桥接方式将相对的两个站台进行相连，
          再通过光纤的方式传输到公交总公司监控中心，
          从而实现中心控制室能实时的了解观看每个站台实际运作状况及相关乘客的流量。<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BRT3号线已于2009 年年底已经成功开通一期(丁桥到城站河坊街，共计13 个停靠站点，
          通过新诺网络科技工程师配合当地SI商与杭州公交公司沟通测试最后确认，
          采用了新诺室外网桥系列做为每个站点相对应的两个站台视频传输设备，合计使用了26 套，
          结合室外网桥的双频内置外置天线可选择的特点，实现了13对站台之间通过内置天线和外接天线两种模式无线视频数据的实时传输，
          项目设备使用至今，运行状况非常良好，得到相关交 通行业同行人士的认可，
          又为新诺产品在杭州公共项目继每年一度的烟花大会数据控制信号传输后新增交通行业应用。
        </div>
        <div>

        </div>
      </div>
    </div>
    <baseBar />
  </div>
</template>

<script>
import topBarY from '@/components/topBarY.vue'
import baseBar from '@/components/baseBar.vue'

export default {
  name: 'caseOne',
  components: {
    topBarY,
    baseBar
  },
  created() {
    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    });
  }
}
</script>

<style scoped>
.bg {
  position: relative;
  width: 1920px;
  height: 480px;
  overflow: hidden;
}

.bg img {
  position: absolute;
  top: -100px;
  width: 1920px;
}

.switchBar {
  width: 1920px;
  height: 70px;
  background-color: #fff;
  text-align: center;
}

.switchBar .tab {
  display: inline-block;
  margin-right: 30px;
  margin-top: 15px;
  border-radius: 5px;
  width: 300px;
  height: 40px;
  background-color: #c3a35d;
  color: #f5f5f5;
  font-family: 'Microsoft Yahei';
  font-size: 16px;
  line-height: 40px;
}

.line {
  margin: 0 auto;
  width: 1400px;
  height: 1px;
  background-color: #c3a35d;
}

.switchContiner {
  width: 1920px;
  padding: 20px;
  background-color: #f7f7f7;
}

.switchContiner .contactContiner {
  margin: 0 auto;
  padding: 40px;
  padding-left: 300px;
  padding-right: 300px;
  width: 1400px;
  height: 460px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .contactContiner .txt {
  font-size: 18px;
  line-height: 34px;
}
</style>
